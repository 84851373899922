<template>
  <div class="agreement">
    <BaseHeaderBar title="Perjanjian Pinjaman" :showCustomerService="false" :showBackButton="true" />
    <iframe :src="frameSrc" frameborder="0"></iframe>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isIOS } from '@/utils/detect'
import { baseURL } from '@/config'
import webviewBack from '@/mixins/webviewBack'
const appType = isIOS ? 2 : 1

export default {
  name: 'Agreement',
  mixins: [webviewBack],
  data () {
    return {
      frameSrc: ''
    }
  },
  created () {
    const { amount } = this.$route.query
    this.frameSrc = `${baseURL}lodranAgreement?lodranAmount=${amount}&apdrpType=${appType}&usdrerID=${this.userBKInfo.userIdBK}`
  },
  computed: mapState(['userBKInfo'])
}
</script>

<style scoped lang="less">
.agreement {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

iframe {
  flex: 1;
  width: 100%;
}
</style>
